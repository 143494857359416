import React from 'react';
import '../css/Homepage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navbar from './Navbar.js';
import SScreenNavbar from './S-Screen-Navbar.js';
import Footer from './Footer.js';
import Image1 from '../assets/images/Homepage/WLS_LOGO_AUSGESCHNITTEN.png'
import Image2 from '../assets/images/Homepage/DominosLogo.png'
import Image3 from '../assets/images/Homepage/JCM_Logo.png'
import Image5 from '../assets/images/Homepage/SamSunLogo.png'
import HeroImg from '../assets/images/Homepage/Hero_img.jpg'
import ImgSection2 from '../assets/images/Homepage/3DImageWebsite2.0.png'
import ImgSection4 from '../assets/images/Homepage/Section4_2.jpeg'
import ImgSection6_1 from '../assets/images/Homepage/packaging_img.jpeg'
import ImgSection6_2 from '../assets/images/Homepage/teamfoto_img.jpeg'
import ImgSection6_3 from '../assets/images/Homepage/austeilen_img.jpeg'
import {faCamera} from '@fortawesome/free-solid-svg-icons';
import {faPenRuler} from '@fortawesome/free-solid-svg-icons';
import {faPersonRays} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';


function Homepage() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/über-uns');
    window.scrollTo(0, 0);
  };
  const handleClick2 = () => {
    navigate('/sponsoren');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <SScreenNavbar />
      <div className='container'>
        <div className='hp-section1' style={{
        backgroundImage: `url(${HeroImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
          <Navbar />
            <div className="hp-section1-text">
                <h1>Dein Fußball Erlebnis wird</h1>
                <h1 className='h1-blue'>ERINNERUNG!</h1>
                <h4 className='h4-white'>Individuelle Sammelkarten für deine Events</h4>
                <a href='mailto:info@team-cards.de?subject=Anfrage für Team-Cards Paket!&body=Hey Team-Cards Team,%0A%0ABitte stellt mir ein Paket zusammen!%0A%0A
                        Verein:%0A%0ATeilnehmer:%0A%0AZeitraum:%0A%0AAndere Infos:%0A%0A' className='hp-section5-button' >Paket Angebot Erhalten</a>
            </div>
         </div>
      </div>
      <div className='container'>
        <div className='hp-section2'>
          <div className='hp-section2-img' style={{
        backgroundImage: `url(${ImgSection2})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}/>
          <div className='hp-section2-h'>
            <div className='bluebar' />
            <h2>SAMMELN & TAUSCHEN, WIE MIT DEN PROFIS</h2>
          </div>
          <div className='hp-section2-text'>
            <h4>
              Wir machen dich zum Star! Bei uns bist du nicht nur Teilnehmer,
              sondern Held deiner eigenen Trading Card. Unsere personalisierten 
              Fußballkarten bringen Spaß und unvergessliche Momente auf den Platz 
              - perfekt zum Sammeln und Tauschen
            </h4>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='hp-section3'>
         <div className='hp-example-pictures'>
            <img src={Image1} alt='Bild'  />
            <img src={Image3} alt="Bild"  /> 
            <img src={Image2} alt="Bild"  /> 
            <img src={Image5} alt="Bild"  /> 
          </div>
        </div>
      </div>

      <div className='container'>
        <div className="hp-section4-img" style={{
        backgroundImage: `url(${ImgSection4})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
          <div className='hp-section4-text'>
            <h1>EIN CAMP, EIN TEAM,</h1>
            <h1 className='h1-blue'>UNZÄHLIGE ERINNERUNGEN!</h1>
            <h4 className='h4-white'>
              Mit unseren Karten bleibt das Fußballcamp für immer
              in Erinnerung. Jeder Spieler hält seine besonderen Momente in den Händen - 
              und das nicht nur in Form von Fotos, sondern auch als spielbare Sammelkarte, die stolz mit nach Hause geht
            </h4>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='hp-section5'>
          <div className='hp-section5-h'>
            <div className='bluebar' />
            <h2>SO FUNKTIONIERT'S</h2>
          </div>
          <div className='hp-section5-foto'>
            <FontAwesomeIcon icon={faCamera} className='icon' />
            <h3>Starte mit deinem Foto</h3>
            <p>
              Beim Camp wirst du zum Star - wir machen coole Fotos von jedem Teilnehmer,
              bereit für deine ganz persönliche Sammelkarte!
            </p>
          </div>
          <div className='hp-section5-style'>
              <FontAwesomeIcon icon={faPenRuler} className='icon' />
            <h3>Deine Karte, dein Style</h3>
            <p>
              Für jedes Camp gestalten wir deine Individuelle Karte mit deinem Foto.
              in kürzester Zeit wird sie produziert und für dich bereitgestellt.
            </p>
          </div>
          <div className='hp-section5-tauschen'>
              <FontAwesomeIcon icon={faPersonRays} className='icon'/>
            <h3>Tauschen, Sammeln, Erinnern</h3>
            <p>
              Hol dir deine Karte, Tausch mit deinen freunden im Camp und schnapp
              dir deine eigene. Ein unvergessliches Andenken - Spaß garantiert!
            </p>
          </div>
          <a href='mailto:info@team-cards.de?subject=Anfrage für Team-Cards Paket&body=Hey Team-Cards Team,%0A%0ABitte stellt mir ein Paket zusammen!%0A%0A
          Verein:%0A%0ATeilnehmer:%0A%0AZeitraum:%0A%0AAndere Infos:%0A%0A' className='hp-section5-button' >jetzt loslegen!</a>
        </div>
      </div>

      <div className='container'>
        <div className='hp-section6'>
          <div className='hp-section6-h'>
            <h2>DEINE VORTEILE</h2>
            <h1 className='h1-blue'>AUF EINEN BLICK!</h1>
          </div> 

          <div className='hp-section6-1'>
            <div className='hp-section6-1h'>
              <div className='bluebar' />  
              <h3>IHRE MARKE - DIRKET IM SPIEL!</h3>
            </div>
            <div className='hp-section6-1text'>
              <h4>
                Nutzen Sie die Chance, Teil eines unvergesslichen Erlebnisses
                zu werden! Mit unserer einzigartigen Werbefläche auf den Fußballkarten
                erreichen Sie Kinder, Eltern und Fußballfans gleichermaßen. Verbinden
                Sie Ihre Marke mit positiven Emotionen und fördern Sie gleichzeitig
                den Jugendsport in Ihrer Region.
              </h4>
              <button onClick={handleClick2}>Infos für Sponsoren</button>
            </div>
            <div className='hp-section6-1-img' style={{
        backgroundImage: `url(${ImgSection6_1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }} />
          </div>

          <div className='hp-section6-2'>
            <div className='hp-section6-2h'>
              <div className='bluebar' />  
              <h3>MACH DEIN CAMP UNVERGESSLICH!</h3>
            </div>
            <div className='hp-section6-2text'>
              <h4>
                Mit unseren personalisierten Fußballkarten machst du dein Camp zu einem 
                unvergesslichen Erlebnis. Die Karten fördern den Teamgeist und bieten den
                Kindern einen besonderen Sammelspaß. Gleichzeitig erhält dein Camp eine 
                kreative Werbefläche, um es bei zukünftigen Teilnehmern und Sponsoren attraktiv
                zu präsentieren.
              </h4>
              {/*<button>Infos für Organisatoren</button>*/}
            </div>
            <div className='hp-section6-2-img' style={{
        backgroundImage: `url(${ImgSection6_2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}/>
          </div>

          <div className='hp-section6-3'>
            <div className='hp-section6-3h'>
              <div className='bluebar' />  
              <h3>ERINNERUNGEN, DIE BLEIBEN - KARTEN, DIE VERBINDEN</h3>
            </div>
            <div className='hp-section6-3text'>
              <h4>
                Unsere personalisierten Fußballkarten bieten mehr als nur Sammelspaß - 
                Sie sind bleibende Erinnerungen an tolle Momente im Camp. Für die Kinder
                bedeutet es, ihren eigenen Erfolg in den Händen zu halten und mit Freunden
                zu tauschen. Für Eltern ist es ein wertvolles Souvenir, das die Begeisterung
                ihres Kindes für den Sport festhält.
              </h4>
              <button onClick={handleClick}>Über Uns</button>
            </div>
            <div className='hp-section6-3-img' style={{
        backgroundImage: `url(${ImgSection6_3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}/>
          </div>          

        </div>  
      </div>  
      <Footer />
    </div>
  );
}

export default Homepage;