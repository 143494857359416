import React from 'react';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import SScreenNavbar from './S-Screen-Navbar.js';
import '../css/Persönlichkeitsrechtserklärung.css'


function Persönlichkeitsrechtserklärung() {
    return (
        <div>
            <SScreenNavbar />
            <Navbar backgroundColor='#008cf0' />
            <div className='container'>
                <div className="persönlichkeitsrecht-container">
                    <div  className='persönlichkeitsrecht-text'>
                        <h1 className='h1-black'>
                            Bedingungen der persönlickeitsrechtlichen Einwilligung
                        </h1>
                        <h2>Auf einen Blick</h2>
                        <p> 
                            1.	Das hochgeladene Foto wird im Rahmen des Projekts Team-Cards.de der JCM Beratung und Beteiligungen GmbH zur Erstellung von Sammelkarten für ein Fußballcamp genutzt und mit diesen Sammelkarten veröffentlicht. 
                           <br />
                           <br />
                            2.	Auf den Sammelkarten wird der vollständige Name (d.h. Vor- und Nachname) der abgebildeten Person genannt. 
                            <br />
                            <br />
                            3.	Aus dem hochgeladenen Foto können sich gegebenenfalls Hinweise auf die ethnische Herkunft, Religion oder Gesundheit der abgebildeten Person (z. B. Hautfarbe, Kopfbedeckung, Brille) ergeben.
                            <br />
                            <br />
                            4.	Das Einverständnis kann jederzeit gegenüber der JCM Beratung und Beteiligungen GmbH mit Wirkung für die Zukunft widerrufen werden. 
                            <br />
                            <br />
                            5.	Ich bin befugt, die Einwilligung für die abgebildete Person zu erteilen. Soweit ich nicht allein sorgeberechtigt bin, liegt auch die Einwilligung der anderen sorgeberechtigten Person vor.
                            <br />
                            Wenn die abgebildete Person über 14 Jahre alt ist, müssen sowohl die abgebildete Person als auch alle Sorgeberechtigten mit der Nutzung einverstanden sein.
                        </p>
                    </div>
                </div>  
             </div>          
            <Footer />
        </div>
  );
}

export default Persönlichkeitsrechtserklärung;