import React from 'react';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import SScreenNavbar from './S-Screen-Navbar.js';
import '../css/Datenschutzerklärung.css'


function Datenschutzerklärung() {
    return (
        <div>
            <SScreenNavbar />
            <Navbar backgroundColor='#008cf0' />
            <div className='container'>
                <div className="datenschutz-container">
                    <div  className='datenschutz-text'>
                        <h1 className='h1-black'>
                            Datenschutzerklärung
                        </h1>
                        <h2>1. Datenschutz auf einen Blick</h2>
                        <p>
                            Die folgenden Hinweise geben einen einfachen Überblick darüber, 
                            was mit Ihren personenbezogenen Daten passiert, wenn Sie diese 
                            Website besuchen. Personenbezogene Daten sind alle Daten, mit 
                            denen Sie persönlich identifiziert werden können. Ausführliche 
                            Informationen zum Thema Datenschutz entnehmen Sie unserer unter 
                            diesem Text aufgeführten Datenschutzerklärung.
                        <br />
                        <br />
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen 
                            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich 
                            und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser 
                            Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene 
                            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen 
                            Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
                            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, 
                            wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die 
                            Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) 
                            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff 
                            durch Dritte ist nicht möglich. 
                        </p>
                        <h3>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h3>
                        <p>
                            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. 
                            Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen 
                            Stelle“ in dieser Datenschutzerklärung entnehmen.
                        </p>
                        <h3>Wie erfassen wir Ihre Daten?</h3>
                        <p>
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen, z. B. durch 
                            das Hochladen von Bildern oder das Ausfüllen von Formularen. Andere Daten werden 
                            automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme 
                            erfasst (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
                        </p>
                        <h3>Wofür nutzen wir Ihre Daten?</h3>
                        <p>
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu 
                            gewährleisten. Andere Daten werden zur Bearbeitung Ihrer Anfragen und zur Erstellung 
                            individueller Sammelkarten verwendet.
                        </p>
                        <h3>Welche Rechte haben Sie bezüglich Ihrer Daten?</h3>
                        <p>
                            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck 
                            Ihrer gespeicherten personenbezogenen Daten zu erhalten. Zudem können Sie die Berichtigung,
                            Einschränkung oder Löschung dieser Daten verlangen. Außerdem haben Sie das Recht, unter 
                            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. 
                            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. 
                            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                        </p>
                        <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
                        <h3>Datenschutz</h3>
                        <p>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre 
                            personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie 
                            dieser Datenschutzerklärung.
                        <br/>
                        <br/>
                            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene 
                            Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung 
                            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das 
                            geschieht. 
                        <br/>
                        <br/>
                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. 
                        </p>
                        <h3>Hinweis zur verantwortlichen Stelle</h3>
                        <p>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                        <br/>
                        <br />
                           Vertretungsberechtigter Geschäftsführer: Jan-Luca Maiwaldt
                        <br/>
                            JCM Beratung & Beteiligungen GmbH
                        <br/>
                            Diekkamp 33a
                        <br/>    
                            22359 Hamburg
                        <br/>
                            Telefon: +49 170 2221280
                        <br/>
                            E-Mail: maiwaldt@team-cards.de
                        <br/>
                        <br/>
                            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und 
                            Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet. 
                        </p>
                        <h3>Speicherdauer</h3>
                        <p>
                            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre 
                            personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen 
                            geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen
                            rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche 
                            Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                        </p>
                        <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>
                        <p>
                            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO 
                            bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer 
                            ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage 
                            von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät 
                            (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung 
                            ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf 
                            Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf 
                            Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. F DSGVO erfolgen. 
                            Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
                        </p>
                        <h3>Empfänger von personenbezogenen Daten</h3>
                        <p>
                            Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine Übermittlung von personenbezogenen 
                            Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer Vertragserfüllung 
                            erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten an Steuerbehörden), wenn wir ein berechtigtes Interesse nach 
                            Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern 
                            geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen 
                            Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung geschlossen.
                        </p>
                        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                        <p>
                            iele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                        </p>
                        <h3>
                            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
                            Direktwerbung (Art. 21 DSGVO)
                        </h3>
                        <p>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; 
                            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. 
                            WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER 
                            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, 
                            WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
                        </p>
                        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                        <p>
                            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres 
                            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                        </p>
                        <h3>Recht auf Datenübertragbarkeit</h3>
                        <p>
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, 
                            maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </p>
                        <h3>Auskunft, Berichtigung und Löschung</h3>
                        <p>
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger 
                            und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                        </p>
                        <h3>Recht auf Einschränkung der Verarbeitung</h3>
                        <p>
                            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der 
                            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                            Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. 
                            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen 
                            Interessenüberwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        </p>
                        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                        <p>
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung.
                            Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                            „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                            von Dritten mitgelesen werden.
                        </p>
                        <h3>Widerspruch gegen Werbe-E-Mails</h3>
                        <p>
                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                            Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        </p>
                        <h2>3. Datenerfassung auf dieser Website</h2>
                        <h3>Kontaktformular</h3>
                        <p>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
                            und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                            widerrufbar.
                            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen 
                            oder der Zweck für die Datenspeicherung entfällt(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –insbesondere Aufbewahrungsfristen – bleiben unberührt.
                        </p>
                        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
                        <p>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                            bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                            widerrufbar.
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                            (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
                            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt
                        </p>
                        <h2>4. Plugins und Tools</h2>
                        <h3>Google Fonts (lokales Hosting)</h3>
                        <p>
                            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google
                            bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet
                            dabei nicht statt.
                            Weitere Informationen zu Google Fonts finden Sie unter
                            https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google:
                            https://policies.google.com/privacy?hl=de.

                        </p>
                        <h2>5. Eigene Dienste</h2>
                        <h3>Google Drive</h3>
                        <p>
                            Wir haben Google Drive auf dieser Website eingebunden. Anbieter ist die Google Ireland Limited („Google“),
                            Gordon House, Barrow Street, Dublin 4, Irland.
                            Google Drive ermöglicht es uns, einen Uploadbereich auf unserer Website einzubinden, in dem Sie Inhalte
                            hochladen können. Wenn Sie Inhalte hochladen, werden diese auf den Servern von Google Drive
                            gespeichert. Wenn Sie unsere Website betreten, wird außerdem eine Verbindung zu Google Drive
                            aufgebaut, sodass Google Drive feststellen kann, dass Sie unsere Website besucht haben.
                            Die Verwendung von Google Drive erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an einem zuverlässigen Uploadbereich auf seiner Website.
                            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                            Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                            DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                            europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                            dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                            Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:
                            https://www.dataprivacyframework.gov/participant/5780.
                            Auftragsverarbeitung
                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
                            geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                            gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
                            Weisungen und unter Einhaltung der DSGVO verarbeitet.
                        </p>
                        <h2>6. Verarbeitung personenbezogener Daten</h2>
                        <h3>Verarbeitung von Kinderbildern und -daten</h3>
                        
                        <h3>Speicherdauer</h3>
                        <p>
                            Die hochgeladenen Bilder und Daten werden bis zur Erstellung und Lieferung der Sammelkarten gespeichert. Spätestens 6 Monate nach der Lieferung werden die Daten vollständig gelöscht, 
                            sofern keine gesetzlichen Aufbewahrungsfristen bestehen.
                        </p>
                        <h2>7. Auftragsverarbeitung und Weitergabe von Daten</h2>
                        <h3>Google Drive</h3>
                        <p>
                            Die hochgeladenen Bilder und Daten werden auf Google Drive gespeichert und dort weiterverarbeitet. Google Drive erfüllt die Anforderungen der DSGVO und ist nach dem EU-US Data Privacy 
                            Framework zertifiziert. Es besteht ein Auftragsverarbeitungsvertrag (AVV) mit Google Ireland Limited.
                        </p>

                        <h2>8. Datensicherheit</h2>
                        <p>
                            •	Verschlüsselte Übertragung: Alle Daten, die auf unserer Website hochgeladen werden, werden per HTTPS verschlüsselt übertragen.
                            <br/>
                            <br/>
                            •	Zugriffsschutz: Der Zugriff auf die gespeicherten Bilder und Daten ist auf autorisierte Personen beschränkt.
                            <br/>
                            <br/>
                            •	Serverstandort: Die Daten werden auf Servern innerhalb der EU gespeichert, um die Anforderungen der DSGVO zu erfüllen.
                        </p>
                       
                        <h2>9. Ihre Rechte</h2>
                        <p>
                            Sie haben jederzeit das Recht auf:
                            <br/>
                            <br/>
                            •	Auskunft über Ihre gespeicherten personenbezogenen Daten,
                            <br/>
                            •	Berichtigung,
                            <br/>
                            •	Einschränkung der Verarbeitung oder Löschung Ihrer Daten,
                            <br/>
                            •	Widerspruch gegen die Verarbeitung Ihrer Daten,
                            <br/>
                            •	Übertragung Ihrer Daten in einem maschinenlesbaren Format,
                            <br/>
                            •	Beschwerde bei einer zuständigen Aufsichtsbehörde.
                        </p>
                    </div>
                </div>  
             </div>          
            <Footer />
        </div>
  );
}

export default Datenschutzerklärung;