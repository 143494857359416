import React from 'react';
import Navbar from './Navbar.js';
import SScreenNavbar from './S-Screen-Navbar.js';
import Footer from './Footer.js';
import UuImgSection3 from '../assets/images/ueber_uns/uu-img-tauschen.jpeg'
import UuImgSection5 from '../assets/images/Homepage/teamfoto_img.jpeg'
import UuImgSection5_1 from '../assets/images/ueber_uns/Insta_uberuns_Jojo.jpg'
import UuImgSection5_2 from '../assets/images/ueber_uns/Insta_uberuns_Jan-Luca.jpg'
import UuImgSection5_3 from '../assets/images/ueber_uns/Insta_uberuns_LIlly.jpg'
import '../css/ÜberUns.css'



function ÜberUns() {
  return (
    <div>
      <SScreenNavbar />

      <div className='container'>
        <div className='uu-section1'>
          <Navbar backgroundColor='#008cf0' />
          <div className='uu-section1-h'>
            <h1 className='h1-blue'> DAS STECKT HINTER TEAM-CARDS.DE</h1>
          </div>
          <div className='uu-section1-sub'>
            <h4>Darum machen wir Camps unvergesslich</h4>
          </div>
        </div>
      </div>

      <div className='container'>

        <div className='sp-section2'>
          <div className='hp-section2-h'>
            <div className='bluebar' />
            <h2>DEIN CAMP. DEINE KARTE. DEINE ERINNERUNG.</h2>
          </div>
          <div className='sp-section2-text'>
            <h4>
              Bei Team-Cards.de dreht sich alles um das, was Fußballcamps wirklich ausmacht: die Kinder, 
              ihre Leidenschaft und die unvergesslichen Momente auf und neben dem Platz. Unsere Mission 
              ist es, diese Erlebnisse greifbar zu machen – in Form von individuell gestalteten Trading 
              Cards, die die Teilnehmer als Stars des Camps zeigen.
              <br />
              <br />
              Unsere Karten sind mehr als nur Sammlerstücke. Sie schaffen eine Verbindung zwischen den Teilnehmern, 
              die über das Camp hinausgeht. Die Kinder tauschen und sammeln ihre Karten, um das gesamte Team zusammenzubringen – 
              ein spielerischer Weg, um Freundschaften und Teamgeist zu stärken. Gleichzeitig bieten die Karten eine 
              Plattform für Camps, um ihre Marke zu präsentieren, und für Sponsoren, um auf kreative Weise sichtbar zu werden.
            </h4>
          </div>
        </div>

        <div className='sp-section2'>
          <div className='hp-section2-h'>
            <div className='bluebar' />
            <h2>DAS MACHT UNS BESONDERS:</h2>
          </div>
          <div className='sp-section2-text'>
            <h4>
              <span className='h4-blue'>Individuelles Design: </span>
                Jede Karte wir speziell fü dein Camp und die Teilnehmer gestaltet.
            </h4>
            <h4>
              <span className='h4-blue'>Emotionen im Fokus: </span>
                wir bringen die Besten Momente des Camps direkt in die Hände der kleinen und großen Fußballstars
            </h4>
            <h4>
              <span className='h4-blue'>Sponsoring mit Mehrwert: </span>
                Unsere Karten verbinden Unternehmen aus der Region mit positiven und emotionalen Erlebnissen.
            </h4>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='uu-section3'>
          <div className='uu-section3-img' style={{
        backgroundImage: `url(${UuImgSection3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}/>
          <div className='uu-section3-sub'>
            <div className='uu-section3-h'> 
              <h3>UNSERE VISION:</h3>
            </div>
            <div className='uu-section3-text'>
              <h4>
              Wir glauben, dass jedes Camp eine Geschichte zu erzählen hat – 
              und unsere Karten sind der perfekte Weg, um diese Geschichten 
              zu teilen. Ob als Erinnerung, Werbemittel oder einfach als Spaßfaktor: 
              Team-Cards.de macht dein Camp einzigartig.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='sp-section5-img' style={{
        backgroundImage: `url(${UuImgSection5})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
          <div className='sp-section5-text'>
            <h4 className='h4-white'>
              Gemeinsam machen wir Fußballcamps unvergesslich und schaffen wertvolle Verbindungen.
              Lassen Sie uns über Ihre Möglichkeiten sprechen und ein individuelles Sponsoring-Paket für Sie schnüren.
              <br/>
              Kontaktieren Sie uns jetzt und wir zeigen Ihnen, wie Ihre Marke ein Teil von der Team-Cards Bewegung werden kann!
            </h4>
          </div> 
        </div>  
      </div>

      <div className='container'>
        <div className='uu-section5'>
          <div className='uu-section5-h'>
            <h1 className='h1-blue'>UNSER TEAM</h1>
          </div>
          <div className='uu-section5-sub'>
            <div className='uu-section5-1-img' style={{
        backgroundImage: `url(${UuImgSection5_1})`,
      }}/>
            <div className='uu-section5-sub-text'>
              <div className='uu-section5-1h'>
                <div className='bluebar' />
                <h3>Fußballer und CEO</h3>
              </div>
              <div className='uu-section5-1text'>
                <h4>
                Julius ist das organisatorische Herz von TeamCards. Als ehemaliger Fußballspieler 
                in der Akademie von Werder Bremen und aktuell Fußballspieler am Stonehill College in Boston, USA, 
                bringt er seine große Leidenschaft und Expertise im Fußball in jedes Projekt ein.
                <br />
                <br />
                Er steht in direktem Austausch mit den Fußballcamps, sorgt für einen reibungslosen Ablauf und managed
                das Team mit viel Engagement und Weitblick. Seine langjährige Erfahrung im Fußball gibt ihm ein tiefes 
                Verständnis für die Wünsche und Bedürfnisse der Camps und der Kinder.
                <br />
                <br />
                „Fußball ist nicht nur ein Sport – es ist eine Leidenschaft, die verbindet.“
                </h4>
              </div>
            </div>
          </div>

          <div className='uu-section5-sub'>
            <div className='uu-section5-2-img' style={{
        backgroundImage: `url(${UuImgSection5_2})`,
      }} />
            <div className='uu-section5-sub-text'>
              <div className='uu-section5-1h'>
                <div className='bluebar' />
                <h3>Student und CTO</h3>
              </div>
              <div className='uu-section5-2text'>
                <h4>
                Jan-Luca ist das technische Genie hinter TeamCards. Als CTO programmiert er unser Bearbeitungstool 
                und kümmert sich um alle technischen Aspekte unseres Projekts – von der Entwicklung bis zur Umsetzung.
                Er bringt nicht nur seine technische Expertise ein, sondern auch innovative Ideen, die TeamCards zu etwas 
                Besonderem machen.
                <br />
                <br />
                „Technologie trifft Kreativität – das ist mein Ansatz.“
                </h4>
              </div>
            </div> 
          </div>

          <div className='uu-section5-sub'>
            <div className='uu-section5-3-img' style={{
        backgroundImage: `url(${UuImgSection5_3})`,
      }}/>
            <div className='uu-section5-sub-text'>
              <div className='uu-section5-1h'>
                <div className='bluebar' />
                <h3>Designerin und CMO</h3>
              </div>
              <div className='uu-section5-3text'>
                <h4>
                Lilly ist die kreative Seele von TeamCards. Mit ihrem Talent und ihrer Leidenschaft fürs Gestalten ist sie 
                für alle Designaspekte verantwortlich, von den Karten bis hin zur Verpackung.
                Sie studiert Architektur und bringt ihre langjährige Erfahrung mit Photoshop und anderen Design-Tools ein, 
                um einzigartige und hochwertige Produkte zu schaffen. Ihre Liebe zu Kindern inspiriert sie dazu, Designs zu
                entwickeln, die nicht nur professionell, sondern auch kinderfreundlich und einprägsam sind.
                <br />
                <br />
                „Mit Kreativität und Herz jedem Kind ein Lächeln ins Gesicht zaubern – das ist mein Ziel.“
                </h4>
              </div>
            </div>  
          </div>

        </div>
      </div>
      <Footer />
    </div>


    

  );
}

export default ÜberUns;